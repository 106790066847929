import axios from 'axios';
const URL = process.env.NEXT_PUBLIC_API_URL;

export default async function ValidateSession() {
  const session = JSON.parse(localStorage.getItem('sessionMitox'));
  console.log({ session });
  try {
    const resp = await axios.post(`${URL}/users/validateSession`, null, {
      headers: { Authorization: session.token },
    });
    return resp;
  } catch (error) {
    if (error && error.status === 401) {
      console.log('Error 401: Unauthorized');
      console.clear();
      localStorage.clear();
      window.location.reload();
    } else {
      console.clear();
      console.log(error);
      localStorage.clear();
      window.location.reload();
    }
  }
}
