import styled from '@emotion/styled';
import { Box, Icon, IconButton, Tooltip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const FloatBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  zIndex: 100,
  // border: '1px solid red',
}));

export default function FloatWhatsapp() {
  const supportNumber = '573145427343';
  const redirectSupport = () => {
    // https://api.whatsapp.com/send/?phone=573114688342&text&type=phone_number&app_absent=0
    window.open(`https://api.whatsapp.com/send/?phone=${supportNumber}`, '_BLANK');
  };
  return (
    <>
      <FloatBox sx={{ p: 2, mr: 1 }}>
        <Tooltip title='Comunícate con soporte' arrow>
          <IconButton onClick={redirectSupport}>
            <Icon
              sx={{
                fontSize: '5rem',
                color: '#7AD06D',
              }}
            >
              <WhatsAppIcon
                sx={{
                  fontSize: '5rem',
                  color: '#7AD06D',
                }}
              />
            </Icon>
          </IconButton>
        </Tooltip>
      </FloatBox>
    </>
  );
}
