import '../styles/globals.css';
import '../styles/OwlCarousel.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '../utils/theme';
import createEmotionCache from '../utils/createEmotionCache';
import { CacheProvider } from '@emotion/react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import ValidateSession from '@/utils/validateSession';
import { appWithTranslation } from 'next-i18next';
import cookie from 'cookie';
import FloatWhatsapp from '@/components/FloatWhatsapp';

const clientSideEmotionCache = createEmotionCache();

function MyApp({ Component, emotionCache = clientSideEmotionCache, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const cookies = cookie.parse(document.cookie);
    console.log({ cookies: cookies.cookies });
    if (!cookies.cookies) {
      console.log('no cookies');
      let userLang = navigator.language; // 'en-US'
      userLang = userLang.split('-')[0]; // just need the lang, ex: 'es' or 'en
      console.log({ userLang });
      document.cookie = `language=${userLang};path=/`;
    }

    const session = localStorage.getItem('sessionMitox');
    if (
      !session &&
      !router.pathname.startsWith('/auth') &&
      !router.pathname.startsWith('/removeLocalStorage') &&
      !router.pathname.startsWith('/test')
    ) {
      router.push('/auth/login');
    } else if (session) {
      (async () => {
        await ValidateSession();
      })();
      if (router.pathname.startsWith('/auth')) {
        router.push('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
      <FloatWhatsapp />
    </CacheProvider>
  );
}

export default appWithTranslation(MyApp);
