import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#EF6F3C',
    },
    secondary: {
      main: 'rgba(99, 106, 133, 1)',
    },
    text: {
      primary: 'rgba(10, 2, 55, 1)',
      secondary: 'rgba(236, 101, 35, 1)',
      disabled: 'rgba(99, 106, 133, 1)',
    },
    border: {
      main: 'rgba(0, 0, 0, 0.23)',
    },
  },
  typography: {
    fontFamily: 'Outfit',
    defaultProps: {
      style: {
        color: '',
      },
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: 'none',
          borderRadius: '8px',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // custom breakpoints
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
});
